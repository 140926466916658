<template>
  <div>
    <el-dialog
      :title="`批量编辑  (您正在同时编辑${currentSelect.length}条${level == 'adset' ? '广告组' : '广告'})`"
      :visible="batchEditShow"
      width="80%"
      @close="close"
      @open="openInit"
      append-to-body
      :close-on-click-modal="false"
      v-dialog-drag
      class="editDialog"
    >
      <div
        class="editCon"
        v-loading="loading"
      >
        <div class="leftList">
          <choose-list
            :list="adSetList"
            @setSelectList="setSelectList"
            :defaultSelect="currentSelect"
            v-if="selectList.length"
            :currentLevel="level"
            :mediaTypeMap="mediaTypeMap"
            :invaild_insterest="statusData ? statusData.invaild_insterest : []"
          ></choose-list>
        </div>
        <!-- <div class="rightCon" v-if=" allList.length && currentLevel == 'campaign'">
                    <ad-series  :currentSelect="currentSelect" :baseData="[...allList]" v-if="batchEditShow" :updateList='updateAdSeriesData' ref="campaign" @setCampaignData="setCampaignData" v-loading="load" labelWidth="140px"></ad-series>
                </div> -->
        <div
          class="rightCon"
          v-if="adSetList.length && currentLevel == 'adset' && currentSelect.length"
        >
          <targeting
            :currentSelect="currentSelect"
            :baseData="[...adSetList]"
            v-if="batchEditShow"
            :updateList="upadteAdSetData"
            @setData="setData"
            ref="targeting"
            v-loading="load"
            :account="accountId"
            :load="load"
            @setBaseName="setBaseName"
            :targetUserId="currentRowNum"
            :statusDataItem.sync="statusData"
          ></targeting>
        </div>
        <div
          class="rightCon"
          v-if="adList.length && currentLevel == 'ad' && currentSelect.length"
        >
          <creative
            :currentSelect="currentSelect"
            :baseData="[...adList]"
            v-if="batchEditShow"
            :updateList="updateAdData"
            ref="creative"
            @setAdData="setAdData"
            v-loading="load"
            :account="accountId"
            @setBaseName="setBaseName"
            :targetUserId="currentRowNum"
          ></creative>
        </div>
      </div>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="close">取 消</el-button>
        <el-button
          type="primary"
          @click="sure"
          v-if="flag"
          id="bacthEditEvent"
          >确 定</el-button
        >
        <el-button
          type="primary"
          @click="tryAgain"
          v-if="!flag"
          >重 试</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import chooseList from './chooseList.vue';
import targeting from './adset/targeting';
import creative from './ads/creative';
import adSeries from './campaign/adSeries';
import initData from './initData';
import {
  batchFetchAdSet,
  batchUpdateAdSet,
  batchFetchAd,
  batchUpdateAd,
  batchEditFetch,
  batchEdit,
} from '@/api/adManagement';
import { accountPages, saleSearch } from '@/api/creatAd';
import { domainReg } from '@/utils/utils';
import tools from '../../createAd/components/tools';
export default {
  components: { chooseList, targeting, creative, adSeries },
  props: ['batchEditShow', 'list', 'initCreatAdData', 'level'],
  mixins: [initData, tools],
  data() {
    return {
      selectList: [], //选择的广告组
      currentSelect: [], //选中的广告组
      baseData: [], //所有选择的广告的基本信息
      upadteData: [], //要保存的所有的广告组的信息
      pageList: [], //账户主页
      adSetList: [],
      adList: [],
      parentSelectList: [],
      currentLevel: this.level,
      upadteAdSetData: [],
      updateAdData: [],
      flag: true,
      saveMsgList: [],
      resultStatus: [],
      load: false,
      timer: null,
      loading: false,
      campaignList: [],
      updateAdSeriesData: [],
      allList: [],
      statusData: {},
    };
  },
  computed: {
    // 多账号 currentNum 当前个号
    currentRowNum() {
      return this.list && this.list.length && this.list[0].rowThirdNum ? this.list[0].rowThirdNum : this.selectNum;
    },
    accountId() {
      return this.list && this.list.length && this.list[0].rowAccountId
        ? this.list[0].rowAccountId
        : this.$store.getters.accountId;
    },
    mediaTypeMap() {
      let obj = {};
      this.updateAdData.forEach((item) => {
        obj[item.id] = {
          media_type: item.creative.media_type,
          videoStatus: item.creative.videoStatus,
          videoId: item.creative.videoId,
          thumbUrl: item.creative.thumbUrl,
        };
      });
      console.log(obj);
      return obj;
    },
  },
  created() {
    // if(this.level == 'ad'){
    //     this.getAccountPage()
    // }
  },
  watch: {
    initCreatAdData: {
      handler() {
        this.init = this.initCreatAdData;
      },
    },
    statusData: {
      deep: true,
      handler(val) {
        console.log('---------12', val);
      },
    },
  },
  methods: {
    setBaseName(level, id, name) {
      console.log(level);
      if (level == 'campaign') {
        this.allList.map((campaign, index) => {
          if (id == campaign.id) {
            this.allList[index].name = name;
          }
        });
      }
      if (level == 'adset') {
        this.adSetList.map((adset, i) => {
          if (adset.id == id) {
            this.adSetList[i].name = name;
          }
        });
        // this.allList.map((campaign,index)=>{
        //     adSetList.list.map((adset,i)=>{
        //         if(adset.id == id){
        //             this.allList[index].list[i].name = name
        //         }
        //     })
        // })
        this.upadteAdSetData.map((adset, index) => {
          if (adset.id == id) {
            adset.name = name;
          }
        });
      }
      if (level == 'ad') {
        console.log(this.adSetList);
        this.adSetList.map((ad, ind) => {
          if (ad.id == id) {
            this.adSetList.list[ind].name = name;
          }
        });
        // this.allList.map((campaign,index)=>{
        //     campaign.list.map((adset,i)=>{
        //         adset.list.map((ad,ind)=>{
        //             if(ad.id == id){
        //                 this.allList[index].list[i].list[ind].name = name
        //             }
        //         })
        //     })
        // })
      }
    },
    // 获取账户主页
    async getAccountPage(v) {
      console.log(v);
      await accountPages({ accountId: this.accountId, force: v }).then((res) => {
        let canUse = res.data.filter((v) => v.is_published);
        let notUse = res.data.filter((v) => !v.is_published);
        this.pageList = canUse.concat(notUse);
        if (v) {
          this.$message('更新成功');
        }
      });
    },
    close() {
      if (this.$refs['creative']) {
        this.$refs['creative'].cancleGetbatchVideoStatus = true;
      }
      this.$emit('update:batchEditShow', false);
      this.selectList = [];
      this.baseData = [];
      this.adSetList = [];
      this.adList = [];
      this.currentLevel = '';
    },
    openInit() {
      console.log(this.list);
      this.currentLevel = this.level;
      this.selectList = JSON.parse(JSON.stringify(this.list));
      this.currentSelect = JSON.parse(JSON.stringify(this.list)).map((v) => v.id);
      this.getAccountPage();
      console.log(this);
      // if(this.level == 'adset'){
      //     this.getAdsetList();
      // }
      // if(this.level == 'ad'){
      //     this.getAdsList();
      // }
      this.getList();
    },
    getAdsList() {
      batchFetchAd({ adIds: this.list.map((v) => v.id), accountId: this.accountId }).then((res) => {
        if (res.code == 0) {
          this.baseData = JSON.parse(JSON.stringify(res.data));
        }
      });
    },
    getAdsetList() {
      batchFetchAdSet({ adSetIds: this.list.map((v) => v.id) }).then((res) => {
        this.baseData = JSON.parse(JSON.stringify(res.data));
        this.baseData.map((v) => {
          if (v.targeting[0].exclusions) {
            v.targeting[0].exclusions = [v.targeting[0].exclusions];
          } else {
            v.targeting[0].exclusions = [];
          }
          if (v.targeting[0].flexible_spec) {
            v.targeting[0].flexible_spec = v.targeting[0].flexible_spec.length ? v.targeting[0].flexible_spec : [];
          }
          if (v.targeting[0].genders.length == 2) {
            v.targeting[0].genders = 'all';
          } else {
            v.targeting[0].genders = v.targeting[0].genders[0];
          }
          console.log(v.targeting[0].genders);
        });
        this.baseData = JSON.parse(JSON.stringify(this.baseData));
        // this.$store.commit('initData/setBaseData',this.baseData)
        // console.log(this.$store.getters.baseData);
      });
    },
    // 获取组合信息
    getList() {
      let obj_type = this.level == 'ad' ? 'AD' : 'ADSET';
      this.loading = true;
      console.log(this.list);
      batchEditFetch(
        { obj_type: obj_type, account_id: this.accountId, obj_ids: this.list.map((v) => v.id) },
        this.currentRowNum,
      ).then(async (res) => {
        this.loading = false;
        if (res.code !== 0) {
          this.campaignList = [];
          this.adSetList = [];
          this.adList = [];
          return false;
        }
        let adList = [],
          adSetList = [],
          campaignList = [];
        let list = JSON.parse(JSON.stringify(res.data));
        // this.allList = list;
        let saleList = [];
          const uniqueSaleIds = this.extractUniqueSaleIds(res.data);
          if (uniqueSaleIds.length > 0) {
            saleList = await this.handelSaleSearch(uniqueSaleIds);
          }
        list.map((v) => {
          v.level = 'adset';
          v.is_cbo = v.cbo;
          //   v[`${v.budget_type}_budget`] = v.budget;
          // 处理受众
          v.targeting[0].targeting_automation = v.targeting[0].targeting_automation || {
            advantage_audience: 0,
          };
          if (v.targeting[0].exclusions) {
            v.targeting[0].exclusions = [v.targeting[0].exclusions];
          } else {
            v.targeting[0].exclusions = [];
          }
          if (v.targeting[0].flexible_spec) {
            v.targeting[0].flexible_spec = v.targeting[0].flexible_spec.length ? v.targeting[0].flexible_spec : [];
          }
          if (v.targeting[0].genders && v.targeting[0].genders.length == 2) {
            v.targeting[0].genders = 'all';
          } else {
            v.targeting[0].genders = v.targeting[0].genders ? v.targeting[0].genders[0] : '';
          }
          if (v.ads && v.ads.length) {
            v.ads.map((k) => {
              k.level = 'ad';
              k.objective = v.campaignObjective;
              k.creative = JSON.parse(JSON.stringify(k.adcreative));
              if(saleList && saleList.length){
                  const item = saleList.find((sale) => sale.saleId == k.creative.saleId);
                  console.log(item,'item');
                  k.creative.langId = item ? item.langId : '';
                  k.creative.productId = item ? item.productId : '';
                }
            });
            adList = adList.concat(v.ads);
          }
          // v.level = 'campaign';
          // if(v.adsets && v.adsets.length){
          //     v.adsets.map(k=>{
          //         k.level = 'adset'
          //         k.is_cbo = v.is_cbo;
          //         k.parentId = v.id;
          //         k.start_date = k.start_time;
          //         k.end_date = k.end_time;
          //         k.targeting = [{...k.targeting}]
          //         if(k.targeting[0].exclusions){
          //             k.targeting[0].exclusions = [k.targeting[0].exclusions]
          //         }else{
          //             k.targeting[0].exclusions = []
          //         }
          //         if(k.targeting[0].flexible_spec){
          //             k.targeting[0].flexible_spec = k.targeting[0].flexible_spec.length?k.targeting[0].flexible_spec:[]
          //         }
          //         if(k.targeting[0].genders&&k.targeting[0].genders.length == 2){
          //             k.targeting[0].genders = 'all'
          //         }else{
          //             k.targeting[0].genders = k.targeting[0].genders?k.targeting[0].genders[0]:''
          //         }
          //         if(k.ads && k.ads.length){
          //             k.ads.map(m=>{
          //                 m.level = 'ad'
          //             })
          //             k.list = k.ads
          //             adList = adList.concat(k.ads)
          //         }
          //     })
          //     v.list = v.adsets
          //     adSetList = adSetList.concat(v.adsets)
          // }
        });
        adSetList = [...list];
        this.adSetList = adSetList;
        this.adList = adList;
        let listSelect = adList.filter((v) => this.selectList.includes(v.id));
        if (
          listSelect.filter((v) => v.creative.media_type !== 'asset_feed').length &&
          listSelect.filter((v) => v.creative.media_type == 'asset_feed').length
        ) {
          this.$message({
            type: 'warning',
            message: '动态广告与其他广告不能同时编辑',
          });
          this.selectList = [];
          this.currentSelect = [];
        }
        // this.campaignList = list
        this.upadteAdSetData = JSON.parse(JSON.stringify(adSetList));
        this.updateAdData = JSON.parse(JSON.stringify(adList));
        // this.updateAdSeriesData = JSON.parse(JSON.stringify(list));
        // console.log(this.campaignList);
      });
    },
    setSelectList(v, level) {
      console.log('-----1-21212', v, level);
      this.timer = null;
      this.load = true;
      this.currentSelect = v;
      this.currentLevel = level;
      setTimeout(this.interval, 500);
      // this.$store.commit('initData/setCurrentSelect',v)
    },
    interval() {
      this.load = false;
    },
    setData(v) {
      this.upadteAdSetData = JSON.parse(JSON.stringify(v));
      console.log(this.upadteAdSetData);
    },
    setAdData(v) {
      this.updateAdData = JSON.parse(JSON.stringify(v));
    },
    setCampaignData(v) {
      this.updateAdSeriesData = JSON.parse(JSON.stringify(v));
    },
    saveAdset() {
      console.log(this.$refs.targeting.baseData);
      let list = JSON.parse(JSON.stringify(this.$refs.targeting.listData));
      list.map((v) => {
        v.targeting[0].genders = v.targeting[0].genders === 'all' ? ['M', 'F'] : [v.targeting[0].genders];
        v.targeting[0].exclusions =
          v.targeting[0].exclusions && v.targeting[0].exclusions.length ? v.targeting[0].exclusions[0] : null;
      });
      this.$showLoading();
      batchUpdateAdSet({ adsetForUpdate: list }).then((res) => {
        if (res.code == 0) {
          this.$message({
            type: 'success',
            message: res.comment,
          });
          this.$emit('get-list');

          this.close();
        }
        this.$hideLoading();
      });
    },
    saveAdCreative() {
      let list = JSON.parse(JSON.stringify(this.$refs.creative.listData));
      if (list.some((v) => v.adcreative.show_url && !/^(\w+\.)+\w+$/.test(v.adcreative.show_url))) {
        this.$message({
          type: 'warning',
          message: '请输入正确的显示链接',
        });
        return false;
      }
      let flag = true;
      list.map((v) => {
        if (v.adcreative.media_type == 'muti') {
          v.adcreative.mutiImgs.map((key, index) => {
            delete key.name;
            delete key.fixed;
            if (!key.image) {
              v.adcreative.mutiImgs.splice(index, 1);
            }
            if (v.adcreative.mutiImgs.length < 2) {
              flag = false;
            }
          });
        }
      });
      if (!flag) {
        this.$message({
          type: 'warning',
          message: '轮播图请至少上传2张图片',
        });
        return false;
      }
      this.$showLoading();
      batchUpdateAd({ adForBatches: list }).then((res) => {
        console.log(res);
        if (res.code == 0) {
          this.$message({
            type: 'success',
            message: res.comment,
          });
          this.$emit('get-list');

          this.close();
        }
        this.$hideLoading();
      });
    },
    // 处理受众
    editFlex(list) {
      if (!list) return null;
      console.log(list);
      list.map((v, i) => {
        let flag = false;
        for (let key in v) {
          if (v[key] && v[key].length) {
            flag = true;
          }
        }
        if (!flag) {
          list.splice(i, 1);
        }
      });
      return list.length ? list : null;
    },
    saveList() {
      let adsetList = this.upadteAdSetData.length
        ? JSON.parse(JSON.stringify(this.upadteAdSetData))
        : JSON.parse(JSON.stringify(this.adSetList));
      let baseList = JSON.parse(JSON.stringify(this.adSetList));
      if (
        adsetList.some(
          (v) =>
            v.targeting[0].excluded_geo_locations_places &&
            v.targeting[0].excluded_geo_locations_places.length &&
            !v.targeting[0].excluded_geo_locations_place_radius,
        )
      ) {
        this.$message({
          type: 'warning',
          message: '请填写排除城镇公里数',
        });
        return false;
      }
      if (
        adsetList.some(
          (v) =>
            !(
              (v.targeting[0].geo_locations && v.targeting[0].geo_locations.length) ||
              (v.targeting[0].geo_locations_region && v.targeting[0].geo_locations_region.length) ||
              (v.targeting[0].geo_locations_city && v.targeting[0].geo_locations_city.length)
            ),
        )
      ) {
        this.$message({
          type: 'warning',
          message: '请选择受众地区',
        });
        return false;
      }
      adsetList.map((v) => {
        delete v.ads;
        v.targeting[0].geo_locations =
          v.targeting[0].geo_locations && v.targeting[0].geo_locations.length ? v.targeting[0].geo_locations : null;
        v.targeting[0].excluded_geo_locations =
          v.targeting[0].excluded_geo_locations && v.targeting[0].excluded_geo_locations.length
            ? v.targeting[0].excluded_geo_locations
            : null;
        v.targeting[0].geo_locations_city =
          v.targeting[0].geo_locations_city && v.targeting[0].geo_locations_city.length
            ? v.targeting[0].geo_locations_city
            : null;
        v.targeting[0].geo_locations_region =
          v.targeting[0].geo_locations_region && v.targeting[0].geo_locations_region.length
            ? v.targeting[0].geo_locations_region
            : null;
        v.targeting[0].flexible_spec = this.editFlex(v.targeting[0].flexible_spec);
        let obj = JSON.parse(JSON.stringify(v));
        baseList.map((k) => {
          delete k.ads;
          if (v.id == k.id) {
            v.changed = !(JSON.stringify(obj) == JSON.stringify(k));
          }
        });
        v.targeting[0].genders =
          v.targeting[0].genders === 'all' ? ['M', 'F'] : v.targeting[0].genders ? [v.targeting[0].genders] : null;
        v.targeting[0].exclusions =
          v.targeting[0].exclusions && v.targeting[0].exclusions.length ? v.targeting[0].exclusions[0] : null;
      });
      let adList = this.updateAdData.length
        ? JSON.parse(JSON.stringify(this.updateAdData))
        : JSON.parse(JSON.stringify(this.adList));
      adList.map((v) => (v.adcreative = JSON.parse(JSON.stringify(v.creative))));
      if (adList.some((v) => v.adcreative.show_url && !/^(\w+\.)+\w+$/.test(v.adcreative.show_url))) {
        this.$message({
          type: 'warning',
          message: '请输入正确的显示链接',
        });
        return false;
      }
      // if(adList.some(v=> !v.conversion_domain)){
      //     this.$message({
      //         type:'warning',
      //         message:'请输入正确的网域'
      //     })
      //     return false;
      // }
      let flag = true,
        assetFlag = true,
        videoFlag = true;
      adList.forEach((v) => {
        if (v.adcreative.media_type == 'muti') {
          v.adcreative.mutiImgs.forEach((key, index) => {
            delete key.name;
            delete key.fixed;
            if (!key.image) {
              v.adcreative.mutiImgs.splice(index, 1);
            }
            if (v.adcreative.mutiImgs.length < 3) {
              flag = false;
            }
          });
        }
        if (v.adcreative.media_type == 'asset_feed') {
          let fbAssetFeedSpec = v.adcreative.fbAssetFeedSpec;
          let imgList = fbAssetFeedSpec.imgList || [];
          let videos = fbAssetFeedSpec.videos || [];
          let list = imgList.concat(videos);
          if (
            !fbAssetFeedSpec.mAdFormats.length ||
            !list.length ||
            !fbAssetFeedSpec.titles.length ||
            !fbAssetFeedSpec.mDescriptions.length ||
            !fbAssetFeedSpec.mBodies.length ||
            !fbAssetFeedSpec.actionTypes.length ||
            !fbAssetFeedSpec.linkUrls.length
          ) {
            assetFlag = false;
          }
          // return this.previewBefore(fbAssetFeedSpec);
          if (!this.previewBefore(fbAssetFeedSpec)) {
            return false;
          }
        }
        if (
          (v.adcreative.media_type == 'video' && !v.adcreative.thumbUrl) ||
          (v.adcreative.media_type == 'video' && v.adcreative.videoStatus == 'processing')
        ) {
          videoFlag = false;
        }
      });
      if (!flag) {
        this.$message({
          type: 'warning',
          message: '轮播图请至少上传三张图片',
        });
        return false;
      }
      if (!assetFlag) {
        this.$message({
          type: 'warning',
          message: '动态素材不完整',
        });
        return false;
      }
      if (!videoFlag) {
        this.$message({
          type: 'warning',
          message: '请检查视频封面是否上传',
        });
        return false;
      }
      let baseAds = JSON.parse(JSON.stringify(this.adSetList));
      // 比对广告信息是否完全一样
      adList.map((m) => {
        baseAds.map((v) => {
          if (v.id == m.adSetId) {
            v.ads.map((k) => {
              if (k.id == m.id) {
                let obj = JSON.parse(JSON.stringify(m));
                m.changed = !(JSON.stringify(obj) == JSON.stringify(k));
              }
            });
          }
        });
      });
      // 给每个广告组加上ads字段
      adsetList.map((v) => {
        v.ads = [];
      });
      // 把广告放到广告组里
      adList.map((k) => {
        adsetList.map((m) => {
          if (m.id == k.adSetId) {
            m.ads.push(k);
          }
        });
      });
      this.saveMsgList = JSON.parse(JSON.stringify(adsetList));
      this.saveMethods(adsetList);
    },
    saveMethods(list) {
      this.$showLoading();
      batchEdit({ adSetForBatchEdits: list, account_id: this.accountId }, this.currentRowNum).then((res) => {
        this.$hideLoading();
        console.log(res);
        if (res.code == 0) {
          this.messageTost(res.data.filter((v) => v.obj_status == 'fail'));
        }
      });
    },
    // 失败消息提示
    messageTost(list) {
      if (!list.length) {
        this.$message({
          type: 'success',
          message: '修改成功',
        });
        this.$emit('get-list');
        this.close();
        this.flag = true;
        return false;
      } else {
        this.$message({
          type: 'error',
          message: '存在保存失败的选项，请点击重试，或者忽略',
        });
        this.flag = false;
        this.resultStatus = list;
      }

      // list.map(v=>{
      //     // if(v.obj_status == 'success'){
      //     //     console.log(v);
      //     //     this.$notify({
      //     //         title: '成功',
      //     //         message:   `${v.level == 'ADSET'?'广告组':v.level == 'AD'?'广告':''}: ${v.obj_name} 保存成功`,
      //     //         type: 'success',
      //     //         position: 'bottom-right'
      //     //     });
      //     // }else{
      //     //     this.$notify.error({
      //     //         title: `${v.level == 'ADSET'?'广告组':v.level == 'AD'?'广告':''}: ${v.obj_name} 保存失败`,
      //     //         message: v.result_msg,
      //     //         duration: 0,
      //     //         position: 'bottom-right'
      //     //     });
      //     // }
      // })
    },
    tryAgain() {
      this.resultStatus.map((v) => {
        if (v.obj_level == 'ADSET') {
          this.saveMsgList.map((k) => {
            if (v.obj_status == 'success' && k.id == v.obj_id) {
              k.changed = false;
            }
          });
        } else if (v.obj_level == 'AD') {
          this.saveMsgList.map((k) => {
            k.ads.map((m) => {
              if (m.id == v.obj_id) {
                m.changed = false;
              }
            });
          });
        }
      });
      console.log(this.saveMsgList);
      this.saveMethods(this.saveMsgList);
    },
    extractUniqueSaleIds(data) {
      const saleIds = new Set();

      data.forEach((adset) => {
        // if (campaign.adsets && Array.isArray(campaign.adsets)) {
          // adsets.forEach((adset) => {
            if (adset.ads && Array.isArray(adset.ads)) {
              adset.ads.forEach((ad) => {
                if (ad.adcreative && ad.adcreative.saleId !== undefined) {
                  saleIds.add(ad.adcreative.saleId);
                }
              });
            }
          // });
        // }
      });

      return Array.from(saleIds);
    },
    async handelSaleSearch(ids) {
      console.log('handelSaleSearch', ids);
      if (!ids) return;
      this.$showLoading();
      const res = await saleSearch({ ids });
      this.$hideLoading();
      return res?.data?.content;
    },
    sure() {
      // if(this.level == 'adset'){
      //     this.saveAdset()
      // }
      // if(this.level == 'ad'){
      //     this.saveAdCreative()
      // }
      if (
        this.$refs.targeting?.statusData?.invaild_insterest &&
        this.$refs.targeting.statusData.invaild_insterest.length > 0
      ) {
        this.$notify.error('存在无效的兴趣词，请删除后再操作');
        return;
      }
      this.saveList();
    },
  },
};
</script>
<style lang="scss">
.editDialog {
  .el-dialog__body,
  .el-dialog__footer {
    background: #fff;
  }
}
</style>
<style lang="scss" scoped>
.editCon {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  max-height: 700px;
  overflow-y: auto;
  .leftList {
    flex: 0 0 340px;
    overflow: hidden;
    max-height: 700px;
    overflow-y: auto;
  }
  .rightCon {
    flex: 1;
    padding-left: 20px;
    max-height: 700px;
    overflow-y: auto;
    padding-right: 20px;
  }
}
</style>
